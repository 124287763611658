<template>
  <div class="d-flex align-center">
    <profile-membership class="hide--in-mobile" style="margin-right: 16px"></profile-membership>
    <v-menu offset-y offset-x content-class="header--popup-container hide--in-mobile">
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <div class="user--dropdown-button" v-if="user">
            <img class="profile--picture" :src="user.photo_profile_user" />
            <div class="profile--name">{{ user.name }}</div>
            <i class="ion-ios-arrow-down ml-2"></i>
          </div>
          <div v-else>
            <img src="@/assets/img/icons/user.svg" class="single--button" />
            <i class="ion-ios-arrow-down ml-2"></i>
          </div>
        </div>
      </template>
      <router-link to="/profile" class="popup--item">
        {{ $t('home.sidebarMenu.profile') }}
      </router-link>
      <router-link to="/product" class="popup--item">
        {{ $t('home.sidebarMenu.product') }}
      </router-link>
      <router-link to="/" class="popup--item">
        {{ $t('home.sidebarMenu.myListing') }}
      </router-link>
      <router-link to="/order" class="popup--item">
        {{ $t('home.sidebarMenu.myOrder') }}
      </router-link>
      <router-link to="/coin-usage" class="popup--item">
        {{ $t('home.sidebarMenu.coinUsage') }}
      </router-link>
      <router-link to="/leads" class="popup--item">
        {{ $t('home.sidebarMenu.myLeads') }}
      </router-link>
      <!--    <router-link to="/dashboard/myproject" class="popup&#45;&#45;item">-->
      <!--      {{ $t('home.sidebarMenu.myproject') }}-->
      <!--    </router-link>-->
      <!--    <router-link to="/dashboard/mybooking" class="popup&#45;&#45;item">-->
      <!--      {{ $t('home.sidebarMenu.mybooking') }}-->
      <!--    </router-link>-->
      <!--    <router-link to="/favorites" class="popup&#45;&#45;item">-->
      <!--      {{ $t('home.sidebarMenu.fave') }}-->
      <!--    </router-link>-->
      <div class="popup--item" @click="logout">
        {{ $t('home.sidebarMenu.logout') }}
      </div>
    </v-menu>
  </div>
</template>

<script>
import auth from '@/mixins/auth.js';
import ProfileMembership from '@/components/utils/profile-membership';
import { mapState } from 'vuex';

export default {
  name: 'header-user',
  mixins: [auth],
  components: {
    ProfileMembership,
  },
  computed: {
    ...mapState({
      user: (state) => state.global.user,
    }),
  },
};
</script>
