<template>
  <div class="header--categories">
    <div class="container d-flex align-center">
      <div class="all--categories">
        <div
          v-for="(item, index) in subheaders"
          :key="`main-cat-header-${index}`"
          class="single--category"
          :class="{ selected: $route.path === item.url }"
        >
          <router-link :to="item.url">{{ $t(item.name) }}</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HelperMixin from '@/mixins/helpers';

export default {
  name: 'header-categories',
  mixins: [HelperMixin],
  data: () => ({
    subheaders: [
      {
        name: 'home.sidebarMenu.product',
        url: '/product',
      },
      {
        name: 'home.sidebarMenu.myListing',
        url: '/',
      },
      {
        name: 'home.sidebarMenu.myOrder',
        url: '/order',
      },
      {
        name: 'home.sidebarMenu.coinUsage',
        url: '/coin-usage',
      },
      {
        name: 'home.sidebarMenu.myLeads',
        url: '/leads',
      },
    ],
  }),
};
</script>

<style scoped></style>
